<template>
  <div class="auth-card">
    <vue-element-loading
      :active="loading"
      :text="loadingtext"
      spinner="bar-fade-scale"
    />
    <CRow>
      <p class="title">ورود به حساب کاربری</p>
      <img src="/img/login-bg-2.png" class="auth-img" alt="" />
    </CRow>
    <CRow class="text-center">
      <CCol col="12">
        <CRow class="text-center my-1">
          <CCol col="12" class="p-0">
            <CInput
              block
              class="input-radius"
              v-model.trim="username"
              placeholder="شماره موبایل"
              autocomplete="username email"
              :is-valid="checkKeyboardUsername"
              invalidFeedback="صفحه کلید را انگلیسی کنید!"
            >
              <template #prepend-content>
                <CIcon name="cil-mobile" />
              </template>
            </CInput>
          </CCol>
        </CRow>
        <CRow class="text-center">
          <CCol class="col-12 p-0">
            <CInput
              block
              v-model.trim="password"
              placeholder="کلمه عبور"
              :type="showPassword"
              autocomplete="curent-password"
              @keyup.enter="doLogin"
              :is-valid="checkKeyboard"
              invalidFeedback="صفحه کلید را انگلیسی کنید!"
            >
              <template #prepend-content>
                <CIcon name="cil-lock-locked" />
              </template>
              <template #append>
                <CButton
                  style="
                    box-shadow: none;
                    background-color: #f0f3f5;
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                  "
                  @click="showPass"
                >
                  <div v-if="showPassword == 'text'">
                    <i class="fa fa-eye" aria-hidden="true"></i>
                  </div>
                  <div v-else>
                    <i class="fa fa-eye-slash" aria-hidden="true"></i>
                  </div>
                </CButton>
                <button class="dynamic-pass-btn mr-3" @click="getDynamicPass()">
                  رمز یکبار مصرف
                </button>
              </template>
            </CInput>
          </CCol>
        </CRow>
        <CRow align-horizontal="center">
          <h6
            class="userTypeTitle mb-2 mt-3"
            :class="{
              unselected: userType === 0,
            }"
          >
            <span
              v-if="userType === 0"
              class="animate__animated animate__zoomInRight animate__infinite animate__slow"
            >
              ←
            </span>
            <span>{{ userLoginText }}</span>
          </h6>
        </CRow>
        <CRow>
          <CCol col="12" class="userTypeWrapper">
            <div
              class="userType"
              @click="setUserType(1)"
              :class="userType == 1 ? 'userTypeSelect' : 'userTypeDeSelect'"
            >
              <CIcon
                name="cilCheck"
                class="animate__animated animate__bounceIn"
                v-if="userType == 1"
              ></CIcon>
              <h6>کشاورز</h6>
            </div>
            <!-- <div
                    class="userType"
                    @click="setUserType(3)"
                    :class="userType == 3 ? 'userTypeSelect' : 'userTypeDeSelect'"
                  >
                    <CIcon name="cilUser" class="img" />
                    <h6>حقوقی</h6>
                  </div> -->
            <div
              class="userType"
              @click="setUserType(2)"
              :class="userType == 2 ? 'userTypeSelect' : 'userTypeDeSelect'"
            >
              <CIcon
                name="cilCheck"
                class="animate__animated animate__bounceIn"
                v-if="userType == 2"
              ></CIcon>
              <h6>نماینده</h6>
            </div>
            <div
              class="userType"
              @click="setUserType(6)"
              :class="userType == 6 ? 'userTypeSelect' : 'userTypeDeSelect'"
            >
              <CIcon
                name="cilCheck"
                class="animate__animated animate__bounceIn"
                v-if="userType == 6"
              ></CIcon>
              <h6>متخصص</h6>
            </div>
            <div
              class="userType"
              @click="setUserType(4)"
              :class="userType == 4 ? 'userTypeSelect' : 'userTypeDeSelect'"
            >
              <CIcon
                name="cilCheck"
                class="animate__animated animate__bounceIn"
                v-if="userType == 4"
              ></CIcon>
              <h6>کارمند مزرعه</h6>
            </div>
          </CCol>
        </CRow>
        <CRow class="text-center my-3 justify-content-center gap-5">
          <CButton color="primary" block @click="doLogin" @keyup.enter="doLogin"
            >ورود به حساب</CButton
          >
          <CButton block v-on:click="doRegister" class="btn text-success btn-block border">میخواهم ثبت نام کنم </CButton>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import useVuelidate from "@vuelidate/core";
import { required } from "vuelidate/lib/validators";

import { detectKeyboardLanguageService } from "../services/detectKeyboardLanguage.service";

export default {
  name: "cmpLogin",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {},
  data: () => ({
    loading: false,
    loadingtext: "در حال ارسال اطلاعات ...",
    userLoginText: "نوع کاربری خود را انتخاب کنید ",
    username: "",
    password: "",
    showPassword: "password",
    deferredPrompt: null,
    showBanner: false,
    userType: 0,
    typeName: "",
  }),
  validations: {
    username: {
      required,
    },
    password: {
      required,
    },
  },
  methods: {
    ...mapActions("login", ["login", "logout", "dynamicPassword"]),
    reset() {
      this.$refs.form.reset();
    },
    async dismiss() {
      this.deferredPrompt = null;
      this.showBanner = false;
    },
    async install() {
      this.deferredPrompt.prompt();
      this.showBanner = false;
    },
    showPass() {
      if (this.showPassword === "password") {
        this.showPassword = "text";
      } else {
        this.showPassword = "password";
      }
    },
    checkKeyboard() {
      const isEng = detectKeyboardLanguageService.detectLanguage(
        this.password.trim()
      );
      return isEng;
    },
    checkKeyboardUsername() {
      const isEng = detectKeyboardLanguageService.detectLanguage(
        this.username.trim()
      );
      return isEng;
    },
    async doLogin() {
      this.v$.$validate();
      if (this.v$.$error) {
        this.$notify({
          group: "auth",
          title: "پیام سیستم ",
          text: "اطلاعات را تکمیل کنید <br /><br />",
          type: "error",
        });
        return;
      } else {
        if (this.userType > 0) {
          this.loading = true;
          const { username, password, userType } = this;
          let loginState = await this.login({ username, password, userType });
          this.loading = false;
          if (loginState) {
            if (loginState.state == false) {
              this.$notify({
                group: "auth",
                title: "پیام سیستم ",
                text: `${loginState.message} <br /><br />`,
                type: "error",
              });
              return;
            } else {
              this.$notify({
                title: "پیام سیستم ",
                text: `${loginState.message} <br /><br />`,
                type: "success",
              });
              return;
            }
          }
        } else {
          this.$notify({
            group: "auth",
            title: "پیام سیستم ",
            text: `نوع کاربری را انتخاب کنید<br /><br />`,
            type: "error",
          });
        }
      }
    },
    doRegister() {
      this.$emit("toggleCmp", "Register");
    },
    doResetPassword() {
      this.$emit("toggleCmp", "ResetPassword");
    },
    setUserType(type) {
      this.userType = type;
      switch (type) {
        case 1:
          this.userLoginText = "ورود با نوع کاربری کشاورز";
          break;
        case 2:
          this.userLoginText = "ورود با نوع کاربری نماینده";
          break;
        case 3:
          this.userLoginText = "ورود با نوع کاربری حقوقی";
          break;
        case 4:
          this.userLoginText = "ورود با نوع کاربری کارمند مزرعه";
          break;
        case 6:
          this.userLoginText = "ورود با نوع کاربری متخصص";
          break;
        default:
          this.userLoginText = "نوع کاربری خود را انتخاب کنید  : ";
          break;
      }
    },
    async getDynamicPass() {
      if (this.userType != 0 && this.username) {
        const { username, userType } = this;
        let result = await this.dynamicPassword({
          phone: username,
          userType: userType,
        });
        if (result.succeeded) {
          this.$notify({
            group: "auth",
            title: "پیام سیستم ",
            text: `رمز عبور یکبار مصرف برای شما ارسال شد <br /><br />`,
            type: "success",
          });
        } else {
          this.$notify({
            group: "auth",
            title: "پیام سیستم ",
            text: `${result.message} <br /><br />`,
            type: "error",
          });
        }
      } else {
        if (this.userType === 0) {
          this.$notify({
            group: "auth",
            title: "پیام سیستم ",
            text: `نوع کاربری را انتخاب کنید<br /><br />`,
            type: "error",
          });
        } else {
          this.$notify({
            group: "auth",
            title: "پیام سیستم ",
            text: `نام کاربری را وارد کنید <br /><br />`,
            type: "error",
          });
        }
      }
    },
  },
  watch: {},
  computed: mapState({
    classObject: (state) => state.theme.darkMode,
  }),
  mounted() {
    this.logout();
  },
  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
};
</script>
<style>
.form-group {
  margin-bottom: 0 !important;
  border-radius: var(--border-radius) !important;
}
</style>
<style scoped>
.title {
  font-size: 1.4rem;
  width: 100%;
}
.form-control[type="password"] {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.userTypeWrapper {
  border-radius: 9px;
  padding-bottom: 1em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.userType {
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  text-align: right;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 7px;
}

.userType.userTypeSelect {
  background-color: var(--secondary);
  color: #e8e8e8;
}
.userType.userTypeDeSelect {
  background-color: rgb(223 164 34 / 15%);
  color: var(--primary);
}

.dynamic-pass-btn {
  border: none;
  background-color: var(--secondary);
  color: #fff;
  font-size: 0.8em;
  font-weight: 700;
  border-radius: 8px;
}

.dynamic-pass-btn:hover {
  background-color: var(--primary);
}

.userTypeTitle.unselected {
  color: #d04848;
}
</style>
