<template>
  <div class="auth-card">
    <vue-element-loading
      :active="loading"
      :text="loadingtext"
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <CRow>
      <p class="title">
        ثبت نام در سامانه
      </p>
      <!--      <img src="/img/login-bg-2.png" class="auth-img" alt=""/>-->
    </CRow>
    <CRow class="mt-3">
      <CCol col="12">
        <v-stepper :steps="steps" v-model="step" ref="stepper" :title="`<span>te</span>`">

        </v-stepper>
      </CCol>
    </CRow>
    <CRow class="text-center">
      <CCol col="12" class="d-flex flex-column gap-20">
        <template v-if="step === 1">
          <transition name="fade">
            <CRow class="box-style gap-10 my-4">
              <CCol col="12">
                <h6>
                  اطلاعات فردی
                </h6>
              </CCol>
              <CCol col="12">
                <CInput
                  type="text"
                  v-model="v$.firstName.$model"
                  placeholder="نام"
                  class="insertInput"
                  autocomplete="off"
                  onfocus="this.removeAttribute('readonly');"
                  id="firstName"
                >
                  <template #prepend>
                    *
                  </template>
                </CInput>
                <label
                  for="firstName"
                  class="error"
                  v-if="v$.firstName.$error"
                  v-text="
                   v$.firstName.onlyChar
                  ? 'نام خود را وارد کنید'
                  : 'تنها مجاز به وارد کردن حروف فارسی هستید'
              "
                ></label>
              </CCol>
              <CCol col="12">
                <CInput
                  type="text"
                  v-model="v$.lastName.$model"
                  placeholder="نام خانوادگی"
                  class="insertInput"
                  id="lastName"
                  autocomplete="off"
                  onfocus="this.removeAttribute('readonly');"
                >
                  <template #prepend>
                    *
                  </template>
                </CInput>
                <label
                  for="lastName"
                  class="error"
                  v-if="v$.lastName.$error"
                  v-text="
                v$.lastName.onlyChar
                  ? 'نام خانوادگی خود را وارد کنید'
                  : 'تنها مجاز به وارد کردن حروف فارسی هستید'
              "
                ></label>
              </CCol>
              <hr class="mx-3 w-100"/>
              <CCol col="12">
                <CInput
                  id="password"
                  type="password"
                  v-model="v$.password.$model"
                  placeholder="گذرواژه - اختیاری"
                  class="insertInput"
                  autocomplete="off"
                  onfocus="this.removeAttribute('readonly');"
                />
                <label
                  for="lastName"
                  class="error"
                  v-if="v$.confirmPassword.$error"
                >
                  گذرواژه و تکرار گذرواژه می بایست یکسان باشند.
                </label>
              </CCol>
              <CCol col="12">
                <CInput
                  id="confirmPassword"
                  type="password"
                  v-model="v$.confirmPassword.$model"
                  placeholder="تکرار گذرواژه"
                  class="insertInput"
                  autocomplete="off"
                  onfocus="this.removeAttribute('readonly');"
                />
              </CCol>
            </CRow>
          </transition>
        </template>
        <template v-if="step === 2">
          <transition name="fade">
            <CRow class="box-style gap-10 my-4">
              <h6>
                {{ userRegisterText }}
              </h6>
              <CCol class="gap-5 d-flex flex-column justify-content-start">
                <div
                  class="userType flex-wrap"
                  @click="setUserType(1)"
                  :class="userType == 1 ? 'userTypeSelect' : 'userTypeDeSelect'"
                >
                  <input
                    type="radio"
                    v-model="userType"
                    name="usertype"
                    value="1"
                    @click="setUserType(1)"
                  />
                  <h6 class="chip bg-secondary text-white">کشاورز</h6>
                  <p class="text-muted">
                    در صورت ثبت زمین از 30 هکتار به بالا خدمات کارشناس دریافت میکنید
                  </p>
                </div>
                <div
                  class="userType flex-wrap"
                  @click="setUserType(6)"
                  :class="userType == 6 ? 'userTypeSelect' : 'userTypeDeSelect'"
                >
                  <input
                    type="radio"
                    v-model="userType"
                    name="usertype"
                    value="6"
                    @click="setUserType(6)"
                  />
                  <h6 class="chip bg-secondary text-white">متخصص</h6>
                  <p class="text-muted">
                    امکان همکاری با هر تعداد کشاورز را دارید و برای خدمات هزینه پرداخت
                    می کنید
                  </p>
                </div>
                <div
                  class="userType flex-wrap"
                  @click="setUserType(4)"
                  :class="userType == 4 ? 'userTypeSelect' : 'userTypeDeSelect'"
                >
                  <input
                    type="radio"
                    v-model="userType"
                    name="usertype"
                    value="4"
                    @click="setUserType(4)"
                  />
                  <h6 class="chip bg-secondary text-white">کارشناس</h6>
                  <p class="text-muted">
                    تنها امکان همکاری با 2 کشاورز را دارید و برای خدمات ماهواره هزینه
                    ای پرداخت نمی کنید
                  </p>
                </div>
              </CCol>
            </CRow>
          </transition>
        </template>
        <template v-if="step === 3">
          <transition name="fade">
            <CRow class="box-style gap-10 my-4">
              <h6>
                کد فعالسازی چهار رقمی را وارد کنید
              </h6>
              <CCol col="12">
                <CFormGroup wrapperClasses="input-group pt-1 pb-1" block>
                  <template #input>
                    <masked-input
                      dir="ltr"
                      type="tel"
                      name="phone"
                      class="form-control text-right"
                      :guide="false"
                      v-model="v$.phone.$model"
                      :mask="[
                    '0',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]"
                      placeholder="شماره موبایل"
                    />
                  </template>
                  <template #append>
                    <CButton
                      :disabled="!isPhoneLengthValid && registerBtnState"
                      @click="checkPhonevalid"
                      color="secondary"
                      block
                    >{{
                        countdown > 0 ? formatedCountdown : VerificationBtnTitle
                      }}
                    </CButton
                    >
                  </template>
                </CFormGroup>
                <CRow class="justify-content-center mt-5">
                  <input
                    placeholder="0"
                    class="verifyInput"
                    type="number"
                    maxlength="1"
                    tabindex="2"
                    pattern="\d*"
                    dir="ltr"
                    ref="verifyNum4"
                    v-model="verifyNum4"
                  />
                  <input
                    placeholder="0"
                    class="verifyInput"
                    type="number"
                    maxlength="1"
                    tabindex="2"
                    pattern="\d*"
                    dir="ltr"
                    ref="verifyNum3"
                    v-model="verifyNum3"
                  />
                  <input
                    placeholder="0"
                    class="verifyInput"
                    type="number"
                    maxlength="1"
                    tabindex="2"
                    pattern="\d*"
                    dir="ltr"
                    ref="verifyNum2"
                    v-model="verifyNum2"
                  />
                  <input
                    placeholder="0"
                    class="verifyInput"
                    type="number"
                    maxlength="1"
                    tabindex="2"
                    pattern="\d*"
                    dir="ltr"
                    ref="verifyNum1"
                    v-model="verifyNum1"
                  />
                </CRow>
              </CCol>
            </CRow>
          </transition>
        </template>
        <CRow class="text-center my-3 justify-content-center gap-10">
          <CCol col="12">
            <CButton
              block
              @click="step === 3 ? registerUser() : isValidate()"
              color="primary">
              {{ step === 3 ? "ثبت نام و ورود" : "مرحله ی بعد" }}
            </CButton>
          </CCol>
          <CCol col="12">
            <CButton
              block
              class="animate__animated animate__fadeIn"
              v-if="step > 1"
              @click="step > 1 && $refs.stepper.previous()"
              color="default">مرحله ی قبل
            </CButton>
          </CCol>
        </CRow>
        <hr/>
        <CRow>
          <CCol col="12">
            <CButton :tabindex="3" block v-on:click="doLogin">
              بازگشت به صفحه ورود
            </CButton>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import {mapActions} from "vuex";
import useVuelidate from "@vuelidate/core";
import {required, minLength, helpers} from "vuelidate/lib/validators";
import MaskedInput from "vue-text-mask";

const onlyChar = helpers.regex("onlyChar", /^[ _آ-یءچ]+$/);
import * as moment from "moment";
import "moment-duration-format";
import {VStepper} from "vue-stepper-component";

function passwordValidation(value, siblings) {
  if(siblings.password){
    return value === siblings.password
  }
  return true;
}
export default {
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  name: "comRegister",
  components: {
    MaskedInput,
    VStepper
  },
  data: () => ({
    steps: 3,
    step: undefined,
    loading: false,
    loadingtext: "در حال ارسال اطلاعات ...",
    phone: null,
    userType: null,
    otpModal: false,
    userRegisterText: "با کدام نوع کاربری در سامانه ثبت نام می کنید ؟",
    VerificationBtnTitle: "ارسال کد فعالسازی",
    isPhoneLengthValid: false,
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    verifyNum1: null,
    verifyNum2: null,
    verifyNum3: null,
    verifyNum4: null,
    registerBtnState: false,
    timer: null,
    countdown: 0,
  }),
  validations: {
    firstName: {
      required,
      onlyChar,
    },
    lastName: {
      required,
      onlyChar,
    },
    phone: {
      required,
      minLength: minLength(10),
    },
    password: {

    },
    confirmPassword: {
      passwordValidation,
    }
  },
  watch: {
    phone: function () {
      if (this.phone.length == 11) {
        this.isPhoneLengthValid = true;
      }
    },
    verifyNum1: function () {
      this.$refs.verifyNum2.focus();
    },
    verifyNum2: function () {
      this.$refs.verifyNum3.focus();
    },
    verifyNum3: function () {
      this.$refs.verifyNum4.focus();
    },
    verifyNum4: function () {
      this.passVerificationCode();
    },
    countdown: function () {
      if (this.countdown == 0) {
        clearInterval(this.timer);
        if (this.registerBtnState == false)
          this.VerificationBtnTitle = "ارسال مجدد کد";
      }
    },
  },
  methods: {
    ...mapActions("register", [
      "sendOtp",
      "checkPhoneState",
      "verifyOtp",
      "register",
    ]),
    async checkPhonevalid() {
      this.v$.$touch();
      if (this.v$.phone.$error) {
        this.$notify({
          group: "auth",
          title: "خطای کد وارد شده",
          text: "شماره موبایل وارد شده معتبر نیست <br /><br />",
          type: "error",
        });
        return;
      } else {
        if (this.userType) {
          this.loading = true;
          this.loadingtext = "بررسی شماره موبایل وارد شده";
          let resultPhoneState = await this.checkPhoneState({
            phone: this.v$.phone.$model,
            userType: this.userType,
          });
          this.loading = false;
          if (!resultPhoneState) {
            await this.sendVerificationCode();
          } else {
            this.$notify({
              group: "auth",
              title: "خطای کد وارد شده",
              text: "شماره موبایل وارد شده معتبر نیست <br /><br />",
              type: "error",
            });
          }
        } else {
          this.$notify({
            group: "auth",
            title: "اطلاعات نا معتبر",
            text: "نوع کاربری خود را انتخاب کنید <br /><br />",
            type: "error",
          });
        }
      }
    },
    async sendVerificationCode() {
      this.loading = true;
      this.loadingtext = "ارسال کد اعتبار سنجی به شماره موبایل";
      let resultOtp = await this.sendOtp({
        phone: this.v$.phone.$model,
        userType: this.userType,
      });
      this.loading = false;
      if (resultOtp) {
        this.countdown = 120;
        this.timer = setInterval(() => {
          this.countdown -= 1;
        }, 1000);
        this.$notify({
          group: "auth",
          title: "پیام سیستم",
          text: "کد فعالسازی برای شما ارسال شد <br /><br />",
          type: "success",
        });
      } else {
        this.$notify({
          group: "auth",
          title: "پیام سیستم",
          text: "خطا رخ داده است مجدد تلاش کنید <br /><br />",
          type: "error",
        });
      }
    },
    async passVerificationCode() {
      this.loading = true;
      this.loadingtext = "بررسی کد اعتبار سنجی وارد شده";
      let VerificationCode = `${this.verifyNum1}${this.verifyNum2}${this.verifyNum3}${this.verifyNum4}`;
      var result = await this.verifyOtp({
        phone: this.v$.phone.$model,
        userType: this.userType,
        verificationCode: VerificationCode,
      });
      this.loading = false;
      this.$notify({
        group: "auth",
        title: "پیام سیستم",
        text: `${result.data.message} <br /><br />`,
        type: result.data.codeState ? "success" : "error",
      });
      this.registerBtnState = result.data.codeState;
      if (result.data.codeState) {
        this.countdown = 0;
        this.VerificationBtnTitle = "شماره شما تایید شد";
      } else {
        this.verifyNum1 = null;
        this.verifyNum2 = null;
        this.verifyNum3 = null;
        this.verifyNum4 = null;
        this.countdown = 0;
        this.VerificationBtnTitle = "ارسال مجدد کد";
      }
    },
    setUserType(type) {
      this.userType = type;
      switch (type) {
        case 1:
          this.userRegisterText = "ثبت نام با نوع کاربری کشاورز";
          break;
        case 4:
          this.userRegisterText = "ثبت نام با نوع کاربری کارشناس";
          break;
        case 6:
          this.userRegisterText = "ثبت نام با نوع کاربری متخصص";
          break;
        default:
          this.userRegisterText = "نوع کاربری خود را انتخاب کنید  : ";
          break;
      }
    },
    doLogin() {
      this.$emit("toggleCmp", "Login");
    },
    async isValidate() {
      if(this.step === 1){
        this.v$.firstName.$touch();
        this.v$.lastName.$touch();
        this.v$.confirmPassword.$touch();
      }
      if(this.step === 2 && !this.userType){
        this.$notify({
          group: "auth",
          title: "پیام سیستم",
          text: "از بین نقش های کاربری یک کدام را انتخاب کنید <br /><br />",
          type: "info",
        });
        return;
      }
      if (this.v$.$errors.length > 0) {
        this.$notify({
          group: "auth",
          title: "پیام سیستم",
          text: "ابتدا خطا ها رو برطرف کنید.",
          type: "info",
        });
      } else {
        this.$refs.stepper.next()
      }
    },
    async registerUser() {
      if (this.registerBtnState) {
        this.v$.$touch();
        if (this.v$.$invalid) {
          this.$notify({
            group: "auth",
            title: "پیام سیستم",
            text: "ابتدا خطا ها رو برطرف کنید.",
            type: "info",
          });
          return;
        } else {
          this.loading = true;
          this.loadingtext = "در حال ارسال اطلاعات ...";
          let model = {
            firstName: this.firstName,
            lastName: this.lastName,
            phone: this.v$.phone.$model,
            password: this.password,
            type: this.userType,
          };
          let registerResult = await this.register({model: model});
          this.loading = false;
          if (!registerResult) {
            this.$notify({
              group: "auth",
              title: "خطا ثبت نام",
              text: "اطلاعات ارسالی معتبر نیست <br /><br />",
              type: "error",
            });
          }
        }
      } else {
        this.$notify({
          group: "auth",
          title: "اطلاعات نا معتبر",
          text: "شماره همراه شما تایید نشده است<br /><br />",
          type: "error",
        });
      }
    },
  },
  computed: {
    formatedCountdown() {
      return moment.duration(this.countdown, "seconds").format("m:ss");
    },
  },
};
</script>

<style>
.v-step .index {
  color: #000;
  box-shadow: none;
  border: 1px solid #909090;
}

.v-step.is-visited .index {
  background: var(--secondary) !important;
  color: #fff !important;
  border: none !important;
  box-shadow: 0 0 20px 0 rgb(18 155 112 / 55%);
}

.v-step.is-active .index {
  background: var(--secondary) !important;
  color: #fff !important;
  border: none !important;
  box-shadow: 0 0 20px 0 rgb(18 155 112 / 55%);
}

.input-group-prepend {
  position: absolute;
  right: 6px;
  color: #ff7943;
}
</style>
<style scoped>
.title {
  font-size: 1.4rem;
  width: 100%;
}

.userType {
  cursor: pointer;
  transition: all 500ms;
  display: flex;
  gap: 5px;
  align-items: baseline;
}

.userType.userTypeSelect::before {
  content: "←";
  font-family: Arial,serif;
  color: #555;
  animation-name: slideInRight;
  animation-duration: .5s;
  animation-fill-mode: both;
}
.userType input {
  display: none;
}
.userTypeDeSelect input[type="radio"] {
  color: #fff;
}

.box-style > h6 {
  text-align: right;
  width: 100%;
  font-size: 16px;
}

.userType h6 {
  text-align: right;
}

.userType p {
  flex-basis: 100%;
}

.userType h5 {
  text-align: right;
}

.userType input[type="radio"] {
  color: #fff;
  font-size: 2em;
}


.verifyInput {
  width: 40px;
  height: 30px;
  border: none;
  text-align: center;
  margin-left: 7px;
  border-bottom: 1px solid #455a64;
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.insertInput {
  position: relative;
}

.error {
  color: #ff00009c;
  font-size: 0.7rem;
  background-color: white;
  position: absolute;
  top: -7px;
  left: 2em;
  padding: 0 15px;
}

.box-style {
  gap: 10px;
}

</style>
