/* eslint-disable no-useless-escape */
export function detectLanguage(key) {
	if (key.length < 1) {
		return;
	}
	if (isEnglish(key)) {
		return true;
	} else {
		return false;
	}
	function isEnglish(key) {
		let p = /\d|\w|[\.\$@\*\\\/\+\-\^\!\(\)\[\]\~\%\&\=\?\>\<\{\}\"\'\,\:\;\_]/g;
		return key.match(p) && key != " ";
	}
}

export const detectKeyboardLanguageService = {
	detectLanguage,
};
