<template>
  <div :class="{ 'c-dark-theme': classObject }">
    <div class="background"></div>
    <notifications group="auth" position="top left" :duration="5000"/>
    <CRow class="rowHeight text-center justify-content-center mx-2">
      <div class="align-self-center absolute-center">
        <h4 class="mb-2 text-white">سامانه هوشمند پایش ماهواره ای صحرا</h4>
        <p class="mb-4 font-sm text-white-80">ارائه خدمات کشاورزی دقیق و هوشمند به تمامی بهره برداران بخش کشاورزی
          کشور</p>
        <login
          @toggleCmp="changeComponent"
          v-if="currentComponent === 'Login'"
          class="animate__animated animate__fadeIn"
        />
        <Register
          @toggleCmp="changeComponent"
          v-if="currentComponent === 'Register'"
          class="animate__animated animate__fadeIn"
        />
      </div>


      <small class="copyright">
        کلیه حقوق مادی و معنوی متعلق به
        <i>
          شرکت صحرا شرق کیمیا
        </i>
        می باشد
      </small>
    </CRow>
    <a class="support animate__animated animate__pulse animate__infinite animate__slow" href="tel://+985133824119">
      <span class="y-center-g-5">
        <CIcon name="cilPhone"/>
        <span>051-33824119</span>
      </span>
      <span class="text-center">
        پشتیبانی صحرا
      </span>
    </a>
  </div>
</template>

<script>
import {mapState} from "vuex";
import Login from "../../components/cmpLogin";
import Register from "../../components/cmpRegister";

export default {
  name: "LoginPage",
  components: {
    Login,
    Register,
  },
  data: () => ({
    currentComponent: "Login",
  }),
  methods: {
    changeComponent(cmp) {
      this.currentComponent = cmp;
    },
  },
  watch: {},
  computed: mapState({
    classObject: (state) => state.theme.darkMode,
  }),
  mounted() {
  },
};
</script>

<style>
.auth-img {
  width: auto;
  height: 186px;
  margin: auto auto 20px;
  filter: hue-rotate(295deg);
}
</style>
<style scoped>

.bgLogin {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
}

.bgLogo {
  background-image: url("/img/saharaLogo.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.rowHeight {
  height: 100vh;
}

.background {
  background-image: url(/img/login-bg-wallpaper.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #00000070;
  background-blend-mode: multiply;
  position: absolute;
  inset: 0;
  filter: blur(5px);
}

html,
body {
  overflow-x: hidden;
  overflow-y: hidden;
}

.row {
  margin-left: 0%;
  margin-right: 0%;
}

.alertStyle {
  position: absolute;
  bottom: 2%;
  right: 1%;
  width: 23%;
  height: 46px;
  text-align: center;
  padding-top: 10px;
  border-radius: 10px;
}

.notification-title {
  text-align: right;
  border-bottom: 1px solid #fff;
  padding-bottom: 5px;
  color: #fff;
}

.vue-notification {
  border-right: 5px solid #187fe7;
  width: 250px;
  margin-top: 6%;
}

.notification-content {
  padding-top: 5px;
  text-align: right;
  color: #fff;
}

.vue-notification.error {
  background: #e54d42;
  border-right-color: #b82e24;
}

.vue-notification.success {
  background: #42a85f;
  border-right-color: #42a85f;
}

@import "~animate.css/animate.css";
button:focus {
  outline: 0 !important;
}

/* .vm.drawer {
  top: 0px;
  margin: 0px 0px 0px auto;
  height: 100%;
  width: 100%;
  max-width: 550px;
} */
.vm-btn-close {
  color: red !important;
  padding: 0px;
  cursor: pointer;
  background: 0 0;
  border: 0;
  float: left !important;
  font-size: 24px;
  line-height: 1em;
  outline: none;
}

.vm-titlebar {
  padding: 10px 15px 10px 15px;
  overflow: auto;
  border-bottom: 1px solid #e5e5e5;
  background-color: #efefef;
}

.vm-content {
  background: #fff;
}

.deleteCellBtn {
  width: 35px;
  height: 35px;
  color: red;
  border: none;
  background-color: #ffffff00;
  font-size: 21px;
  float: right;
}

.editCellBtn {
  width: 35px;
  height: 35px;
  background-color: transparent;
  border: none;
  color: #006bf1;
  font-size: 21px;
  padding-top: 3px;
  float: right;
}

.copyright {
  font-size: 12px;
  color: rgb(96, 125, 139);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ddd;
  padding: 6px;
}

.copyright i {
  color: #3e3e3e;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
}

.support {
  position: fixed;
  right: 40px;
  bottom: 50px;
  background-color: var(--primary);
  padding: 16px;
  color: #fff;
  border-radius: 50px;
  border: 4px solid #57575770;
  text-decoration: none;
  display: flex;
  flex-direction: column;
}

.animate__animated{
  animation-fill-mode: backwards;
}

</style>
